<template>
  <div>
    <page-header title='Configurações do certificado'></page-header>
    <v-row justify="end" class="mr-4" v-if="true">
      <v-btn :disabled="$route.name == 'NewCertificateConfig'" color="white" depressed rounded class="mx-2" @click="$router.push({name: 'NewCertificateConfig'})"> 
        <v-icon class="mr-1" size="26" color="primary">mdi-plus-circle-outline</v-icon> 
        Cadastrar assinatura
      </v-btn>


    </v-row>
    <v-row>
      <v-col cols="12" lg="6">
        <v-card outlined style='border-radius: 18px'>
          <v-card-title class='primary white--text py-3 px-5'>
            Assinaturas
          </v-card-title>
          <card-loading-transparent v-if="loading"/>
          <v-card-text v-if="!loading" class="px-0">
            <certificate-list></certificate-list>
          </v-card-text>
        </v-card>

        <v-card outlined style='border-radius: 18px' class="mt-3">
          <v-card-title class='primary white--text py-3 px-5'>
            Configurações de logo
          </v-card-title>
          <card-loading-transparent v-if="loading"/>
          <v-card-text v-if="!loading" class="px-0">
            <general-images-list class="pt-3 px-3"></general-images-list>
          </v-card-text>
        </v-card>

      </v-col>
      <v-col cols="12" lg="6">
        <v-card outlined style='border-radius: 18px;'>
          <v-card-text v-if="$route.name == 'CertificateConfig' && !loading_form">
            <div class="grey--text title">
              Selecione uma assinatura para exibir detalhes e edição.
            </div>
          </v-card-text>
          <card-loading-transparent loadingText="Carregando" v-if="loading_form"/>
          <div v-if="!loading_form">
            <router-view ></router-view>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import Api from '@/api/index'

export default {
  name: 'CertificateConfig',
  components: {
    CertificateCreate: () => import ('./create/CertificateCreate.vue'),
    CertificateList: () => import ('./list/CertificateList.vue'),
    GeneralImagesList: () => import ('./list/GeneralImagesList.vue'),
  },
  data() {
    return {
      loading: false,
      selected_member: null,
      enable_fields: false,
      loading_form: false,
    }
  },
  methods: {
    getData(){
      this.loading = true
      setTimeout(() => {
        this.loading = false
      }, 500);
    },
    ...mapActions({
      // function: 'Module/action'
    })
  },
  computed: {
    ...mapState({
      // data: state=> state.Module.state_var
    })
  },
  mounted () {
    this.getData();
  },
  watch: {
    '$route.path'() {
        this.loading_form = true
      setTimeout(() => {
        this.loading_form = false
      }, 400);
      if(this.$route.params.any_certificate_settings_updated){
        this.getData();
      }
    },

  },
}
</script>

<style scoped>

</style>